import {database} from "./firebase";

const db = database.ref("/early-access");

class NewRestaurantService {
  
  create(restaurant) {
    return db.push(restaurant);
  }

}

export default new NewRestaurantService();