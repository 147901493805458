import {useEffect,useState} from 'react';
import './menu.scss';
import NonVegIcon from '../../assets/veg.svg';
import VegIcon from '../../assets/non-veg.svg';
import downIcon from '../../assets/down-arrow.svg';
import menuIcon from '../../assets/menu book.svg';
import tableIcon from '../../assets/table.svg';
import { useNavigate, useParams } from 'react-router';
import RestaurantDataService from '../../firebase/restaurant';
import CartDataService from '../../firebase/cart';
import { useSearchParams } from 'react-router-dom';
import SignOut from '../../assets/signout.png'
import OrdersIcon from '../../assets/orders.png'
import waiterIcon from '../../assets/waiter.svg'


import User from '../../firebase/user';
import user from '../../firebase/user';

function Menu(){

    const [menuList,setMenuList] = useState([]);
    const [menuListCopy,setMenuListCopy] = useState([]);

    const [updateCartFlag,setUpdateCartFlag] = useState(false);
    const [restaurantDetails,setRestaurantDetails] = useState();

    const { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [cartLength,setCartLength] = useState(0);
    const [waiter,setWaiter]= useState(null);
    const [floatMenu,setFloatMenu] = useState(false);

    useEffect(()=>{
        window.scrollTo(0,0);
        var searchHeight = window.$('.Menu .search-menu').position().top;
        window.$(window).on('scroll',function(e){
          if(window.$('.Menu .search-menu').length&&window.scrollY>searchHeight){
            window.$('.Menu .search-menu').addClass('sticked')
          } else{
            window.$('.Menu .search-menu').removeClass('sticked')
          }
        })
        let key ={ id:id,table:searchParams.get('table')}
        RestaurantDataService.getTableWaiterStatus(key).on('value',function(item){
           setWaiter(item.val())
        })
        RestaurantDataService.get(id).on('value',dataChange)
        return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
        RestaurantDataService.getTableWaiterStatus(key).off('value',function(item){
            setWaiter(item.val())
         })
        }
        
    },[])

    useEffect(()=>{
        console.log(CartDataService.getTotalItems(),'yi')
        setCartLength(CartDataService.getTotalItems())
    },[updateCartFlag])

    const dataChange = (data)=>{
        let menu = data.val()?.['menu']
        console.log('menu',menu)
        setRestaurantDetails(data.val())
        let lists = []
        Object.keys(menu).forEach((item)=>{
            let list = {
                name:menu[item].name,
                key:item,
                subCategories:[]
            }
            if(menu[item].subCategory)
            Object.keys(menu[item].subCategory).forEach((subItem)=>{
                let subCategory = {
                    name:menu[item].subCategory[subItem].name,
                    key:subItem,
                    products:[]
                }
                if(menu[item].subCategory[subItem].products)
                Object.keys(menu[item].subCategory[subItem].products).forEach((subProduct)=>{
                    let product = {
                        key:subProduct,
                        name:menu[item].subCategory[subItem].products[subProduct].name,
                        description:menu[item].subCategory[subItem].products[subProduct].description,
                        price:menu[item].subCategory[subItem].products[subProduct].price,
                        veg:menu[item].subCategory[subItem].products[subProduct].veg,
                        outofstock:menu[item].subCategory[subItem].products[subProduct].outOfStock
                    }
                    subCategory.products.push(product)
                })
                list.subCategories.push(subCategory)
            })
            lists.push(list)
        })
        console.log(lists,'lists')
        setMenuList(lists)
        setMenuListCopy(lists)

    }

    const updateMenu = (e)=>{
       console.log(e.target.value)
       let newList = menuListCopy.filter((item)=>{
           if(item.name.toLowerCase().includes(e.target.value.toLowerCase())){
               return true
           }
           else if(item.subCategories.filter(s => s.name.toLowerCase().includes(e.target.value.toLowerCase())).length > 0){
               return true
           } else{
               let count = 0;
                item.subCategories.forEach((p)=>{
                   p.products.forEach((element)=>{
                     if(element.name.toLowerCase().includes(e.target.value.toLowerCase())){
                       count++;
                     }
                   })
                })
                if(count){
                    return true
                }
                return false;
           }
       })
       console.log(newList)
       setMenuList(newList);
    }

    const scrollToList = (id) =>{
        // window?.$('#root .Menu .category-name,#root .Menu .subcategory,#root .Menu .sub-category-items,#root .Menu .category-items').removeClass('active')
        // window.$('#root .Menu #category-'+id).trigger('click')
        window.$('#root .Menu #category-'+id).trigger('click')
        window?.$('html, body').animate({scrollTop: window?.$('#root .Menu #category-'+id).offset().top -100 }, 'slow');
        
    }

    const updateWaiter=(value)=>{
        let key ={ id:id,table:searchParams.get('table')}
        RestaurantDataService.updateTableWaiterStatus(key,value)
    }

    const addToCart = (item) => {
        setUpdateCartFlag(!updateCartFlag)
        CartDataService.addToCart(item)
    }

    const removeFromCart = (item) => {
        console.log('removeFromCart')
        setUpdateCartFlag(!updateCartFlag)
       CartDataService.removeToCart(item)
    }

    const isInCart = (item) =>{
        console.log(CartDataService.getCartItemQuantity(item.key),'is in cart')
        return CartDataService.getCartItemQuantity(item.key);
    }

    const cartQuantity = (item)=>{
         return CartDataService.getCartItemQuantity(item.key);
    }

    const signOut = () =>{
        navigate('/')
        User.signOut()
    }

   return (
       <div className="Menu">
           <div className="la-costa-fixed-watermark"></div>
           {
               user.getUserId()?
               <>
           <div className="signout" onClick={e=>{
               signOut()
           }}>
               <img src={SignOut} alt="sign out" />
           </div>

           <div className="orders-button" onClick={e=>{
               navigate('/orders')
           }}>
               <img src={OrdersIcon} alt="sign out" />
           </div>

               
               </>
           :''
           }
            <p className="restaurant-name">{restaurantDetails?.name}</p>
            {
                searchParams.get('table')?
                <p className="restaurant-table"><img src={tableIcon} alt="table" />{restaurantDetails?.tables&&(Object.keys(restaurantDetails?.tables)?.indexOf(searchParams.get('table'))+1)!==Object.keys(restaurantDetails?.tables)?.length?'Table no.  '+(Object.keys(restaurantDetails?.tables)?.indexOf(searchParams.get('table'))+1):'Take-away'}</p>
                :<p className="restaurant-table"></p>
            }

            <div className="search-menu">
                <input type="text" placeholder="What do you want to order?" onChange={e=>{updateMenu(e)}}/>
            </div>

            <div className="menu-lists">
                 {
                     menuList?.map(item=>{
                         return (
                             <div className="menu-list">
                             <p className="category-name" id={"category-"+item.key}>{item.name}
                             <span><img src={downIcon} alt="down icon" /></span>
                             </p>
                             <div className="category-items">
                             {
                                 item?.subCategories?.map((subItem)=>{
                                     return (
                                         <div>
                                             {
subItem?.name?.length?
                                         <p className="subcategory">{subItem.name}
                                         <span><img src={downIcon} alt="down icon" /></span> 
                                         </p>:''
                                             }
                                         <div className={`sub-category-items ${!subItem.name?'active':''}`}>
                                         {
                                             subItem?.products?.map((element)=>{
                                                 return (
                                                     <div className="menu-item">
                                                         <div className="menu-details">
                                                             <div className="isVeg"><img src={element.veg==='true'||element.veg===true?VegIcon:NonVegIcon} alt="vegetarian" /></div>
                                                             <p className="name">{element.name}</p>
                                                             <div className="menu-price">₹{element.price}</div>
                                                             <p className="desc">{element.description}</p>
                                                         </div>
                                                         <div className="add-to-cart" key={'eleemnt-'+element.key+updateCartFlag}>
                                                             {
                                                              !isInCart(element)?
                                                              <button onClick={e=>{addToCart(element)}}>Add</button>:
                                                              <button className="updateCart">
                                                              <span onClick={e=>{removeFromCart(element)}}>-</span>
                                                                <p>{cartQuantity(element)}</p>
                                                                <span onClick={e=>{addToCart(element)}}>+</span>
                                                              </button>

                                                             }
                                                         </div>
                                                     </div>
                                                 )
                                             })
                                         }
                                         </div>
                                         </div>
                                     )
                                 })
                             }
                             </div>
                             </div>
                         )
                     })
                 }
            </div>

        {
            !waiter?
          <div className="waiter-button" onClick={e=>{updateWaiter(true)}}>
              <img src={waiterIcon} alt="waiterIcon" />
          </div>:''
        }

           <div className="bottom-buttons">
            <div className={`float-menu ${floatMenu?'active':''}`} onClick={e=>{setFloatMenu(!floatMenu)}}>
                {
                    !floatMenu?
                    <img src={menuIcon} alt="menu icon" />:
                    <div className="float-menu-lists">
                        {
                            menuList.map((item)=>{
                                return (
                                    <div className="float-menu-list">
                                        <p onClick={e=>{scrollToList(item.key)}}>{item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                }
            </div>

            {
                cartLength?
                <div className="cart-button" onClick={e=>{
                    if(searchParams.get('table'))
                    navigate('/cart/'+id+'?table='+searchParams.get('table'))
                    else 
                    navigate('/cart/'+id)
                    }}>
                    <p className="cart-length">{(cartLength) + (cartLength>1?' Items':' Item')}</p>
                    <p className="cart-text">View cart</p>
                </div>:''
            }

           </div>


       </div>
   )
}

export default Menu;