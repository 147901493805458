import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useParams } from 'react-router';
import RestaurantDataService from '../../../firebase/restaurant';
import LineChart from '../../../utils/line-chart';
import './insights.scss';

function RestaurantInsghts(){
    const [orders,setOrders] = useState([])
    const [chartData,setChartData] = useState([])
    const [restaurantDetails,setRestaurantDetails] = useState({})
    const [state, setState] = useState([
        {
          startDate: new Date().setFullYear(new Date().getFullYear(), 0, 1),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
  


    const params = useParams();

    useEffect(()=>{
        RestaurantDataService.get(params.id).on('value',resDataChange)
        RestaurantDataService.getOrdersFromRestaurant(params,new Date().setFullYear(new Date().getFullYear(), 0, 1),Date.now()).on('value',dataChange)
        return ()=>{
        RestaurantDataService.getOrdersFromRestaurant(params,new Date().setFullYear(new Date().getFullYear(), 0, 1),Date.now()).off('value',dataChange)
        RestaurantDataService.get(params.id).off('value',resDataChange)
        }
      },[])
      const resDataChange = (data) =>{
        console.log({...data.val(),key:data.key},'restaurant details')
        setRestaurantDetails({...data.val(),key:data.key})
      }
  
      const dataChange = (item)=>{
        console.log(item.val(),'restaurant orders')
        let prods  = []
        let values = item.val()
  
        Object.keys(item.val()).forEach((item)=>{
          let table = values[item]['fromTable']
          delete values[item]['fromTable']
          delete values[item]['tableClear']
          Object.keys(values[item]).forEach((element)=>{
            let order = values[item][element]
            console.log('value',table,order)
            prods.push(order)
           })
        })


        let chartD = [...prods]

        chartD = chartD.map((item)=>{
            return {
                x:new Date(item['time']),
                y:Number(item['totalPrice'])
            }
        })

        console.log(chartD,'chartD')
  
        setOrders(prods)
        setChartData(chartD)
    }

    const getTotalPrice = () =>{
       let price = 0
       console.log(orders,'orders')
       orders.forEach((item)=>{
           price+= Number(item['totalPrice'])
       })
       return price
    }

    const filterOrderDate = (value)=>{
        console.log(value.selection)
        RestaurantDataService.getOrdersFromRestaurant(params,value.selection.startDate,value.selection.endDate).on('value',dataChange)
        setState([value.selection])
      }

    const getTotalOrders = ()=>{
        return orders.length
    }
    return (
        <div className="Insights">
            <div className="la-costa-fixed-watermark"></div>
           <div className="heading">
             <p>Insights</p>
           </div>

           <DateRange
  editableDateInputs={false}
  onChange={item => {
    filterOrderDate(item)
    }}
  moveRangeOnFirstSelection={false}
  showSelectionPreview={false}
  showMonthAndYearPickers={false}
  ranges={state}
  months={1}
/>

           <div class="insights">
          <div class="insight">
            <div class="heading">
            Total orders
              <div class="score">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 262.361 262.362"
                  width="8"
                  height="8"
                  fill="#44c790"
                >
                  <path
                    d="M286.935 197.287L159.028 69.381c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.287C1.807 200.904 0 205.186 0 210.134s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.425 12.85 5.425h255.813c4.949 0 9.233-1.808 12.848-5.425 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.23-5.427-12.847z"
                  />
                </svg>
                <span>6%</span>
              </div>
            </div>
            <div class="number">
              {getTotalOrders()}
            </div>
          </div>
          <div class="insight">
            <div class="heading">
            Total sales
              <div class="score">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 262.361 262.362"
                  width="8"
                  height="8"
                  fill="#44c790"
                >
                  <path
                    d="M286.935 197.287L159.028 69.381c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.287C1.807 200.904 0 205.186 0 210.134s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.425 12.85 5.425h255.813c4.949 0 9.233-1.808 12.848-5.425 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.23-5.427-12.847z"
                  />
                </svg>
                <span>5%</span>
              </div>
            </div>
            <div class="number">
            ₹ {getTotalPrice()}
            </div>
          </div>
         
        </div>
           
        <LineChart data={chartData}></LineChart>

        </div>
    )
}

export default RestaurantInsghts;