import {useEffect,useState} from 'react';
import './menu.scss';
import NonVegIcon from '../../../assets/veg.svg';
import VegIcon from '../../../assets/non-veg.svg';
import downIcon from '../../../assets/down-arrow.svg';
import pencilIcon from '../../../assets/pencil.svg'
import { useNavigate, useParams } from 'react-router';
import RestaurantDataService from '../../../firebase/restaurant'



function RestaurantMenu(){

   
    const [menuList,setMenuList] = useState([]);
    const [menuListCopy,setMenuListCopy] = useState([]);
    const {id,subId,menuId,catId} = useParams();

    const [restaurantDetails,setRestaurantDetails] = useState();
    const navigate = useNavigate();

    const [floatMenu,setFloatMenu] = useState(false);

    useEffect(()=>{
        window.scrollTo(0,0);
        var searchHeight = window.$('.Restaurant-Menu .search-menu').position().top;
        window.$(window).on('scroll',function(e){
          if(window.$('.Restaurant-Menu .search-menu').length&&window.scrollY>searchHeight){
            window.$('.Restaurant-Menu .search-menu').addClass('sticked')
          } else{
            window.$('.Restaurant-Menu .search-menu').removeClass('sticked')
          }
        })
        RestaurantDataService.get(id).on('value',dataChange)
        return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
        let menu = data.val()?.['menu']
        console.log('menu',menu)
        setRestaurantDetails(data.val())
        let lists = []
        Object.keys(menu).forEach((item)=>{
            let list = {
                name:menu[item].name,
                key:item,
                subCategories:[]
            }
            Object.keys(menu[item].subCategory).forEach((subItem)=>{
                let subCategory = {
                    name:menu[item].subCategory[subItem].name,
                    key:subItem,
                    products:[]
                }
                if(menu[item].subCategory[subItem]?.products)
                Object.keys(menu[item].subCategory[subItem]?.products).forEach((subProduct)=>{
                    let product = {
                        key:subProduct,
                        name:menu[item].subCategory[subItem].products[subProduct].name,
                        description:menu[item].subCategory[subItem].products[subProduct].description,
                        price:menu[item].subCategory[subItem].products[subProduct].price,
                        veg:menu[item].subCategory[subItem].products[subProduct].veg,
                        outofstock:menu[item].subCategory[subItem].products[subProduct].outOfStock
                    }
                    subCategory.products.push(product)
                })
                list.subCategories.push(subCategory)
            })
            lists.push(list)
        })
        console.log(lists,'lists')
        setMenuList(lists)
        setMenuListCopy(lists)
    }

    const updateMenu = (e)=>{
        console.log(e.target.value)
        let newList = menuListCopy.filter((item)=>{
            if(item.name.toLowerCase().includes(e.target.value.toLowerCase())){
                return true
            }
            else if(item.subCategories.filter(s => s.name.toLowerCase().includes(e.target.value.toLowerCase())).length > 0){
                return true
            } else{
                let count = 0;
                 item.subCategories.forEach((p)=>{
                    p.products.forEach((element)=>{
                      if(element.name.toLowerCase().includes(e.target.value.toLowerCase())){
                        count++;
                      }
                    })
                 })
                 if(count){
                     return true
                 }
                 return false;
            }
        })
        console.log(newList)
       setMenuList(newList);
     }
 

   return (
       <div className="Restaurant-Menu">
           <div className="la-costa-fixed-watermark"></div>
            <p className="restaurant-name">{restaurantDetails?.name}</p>
            <p className="restaurant-table">
                </p>

            <div className="search-menu">
                <input type="text" placeholder="What do you want to order?" onChange={e=>{updateMenu(e)}}/>
            </div>

            
            <div className="menu-lists">
                 {
                     menuList.map(item=>{
                         return (
                             <div className="menu-list">
                             <div className="category-name" id={"category-"+item.key}><p>{item.name}
                             <img src={pencilIcon} onClick={e=>{navigate('edit-category/'+item.key)}} alt="edit category" />
                             </p>
                             <span><img src={downIcon} alt="down icon" /></span>
                             </div>
                             <div className="category-items">
                                 
                             {
                                 item.subCategories.map((subItem)=>{
                                     return (
                                         <div>
                                             {
                                                 subItem.name.length?

                                         <p className="subcategory">{subItem.name}
                                         <span><img src={downIcon} alt="down icon" /></span> 
                                         </p> :''
                                             }
                                         <div className={`sub-category-items ${!subItem.name?'active':''}`}>
                                         {
                                             subItem?.products?.map((element)=>{
                                                 return (
                                                     <div className="menu-item">
                                                         <div className="menu-details">
                                                             <div className="isVeg"><img src={element.veg==='true'||element.veg===true?VegIcon:NonVegIcon} alt="vegetarian" /></div>
                                                             <p className="name">{element.name}</p>
                                                             <div className="menu-price">₹{element.price}</div>
                                                             <p className="desc">{element.description}</p>
                                                         </div>
                                                         <div className="add-to-cart">
                                                             <button onClick={e=>{navigate(item.key+'/subCategory/'+subItem.key+'/edit-product/'+element.key)}}>Edit</button>
                                                         </div>
                                                     </div>
                                                 )
                                             })
                                         }
                                         <div className="add-item-admin" onClick={e=>{navigate(item.key+'/subCategory/'+subItem.key+'/add-product')}}>Add</div>
                                         </div>
                                         </div>
                                     )
                                 })
                             }
                             </div>
                             </div>
                         )
                     })
                 }
            </div>

           <div className="bottom-buttons">
            <div className={`float-menu ${floatMenu?'active':''}`} onClick={e=>{navigate('add-category')}}>
                {
                    !floatMenu?
                    <p>+</p>:''
                }
            </div>

            

           </div>


       </div>
   )
}

export default RestaurantMenu;