import {database} from "./firebase";

const db = database.ref("/restaurants");

class RestaurantDataService {
  getAll() {
    return db;
  }

  get(id) {
    return db.child(id);
  }

  create(restaurant) {
    return db.push(restaurant);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }

  getRestaurantMenuCategory(keys){
    return db.child(keys.id).child('menu').child(keys.subId)
  }

  addRestaurantMenuCategory(keys,value){
    return db.child(keys.id).child('menu').push(value)
  }

  editRestaurantMenuCategory(keys,value){
    return db.child(keys.id).child('menu').child(keys.subId).update(value)
  }

  deleteRestaurantMenuCategory(keys){
    return db.child(keys.id).child('menu').child(keys.subId).remove()
  }

  addRestaurantMenuItem(keys,value){
    return db.child(keys.id).child('menu').child(keys.menuId).child('subCategory').child(keys.catId).child('products').push(value)
  }

  getRestaurantMenuItem(keys){
    return db.child(keys.id).child('menu').child(keys.menuId).child('subCategory').child(keys.catId).child('products').child(keys.subId)
  }

   editRestaurantMenuItem(keys,value){
    return db.child(keys.id).child('menu').child(keys.menuId).child('subCategory').child(keys.catId).child('products').child(keys.subId).update(value)
   }

   deleteRestaurantMenuItem(keys){
    return db.child(keys.id).child('menu').child(keys.menuId).child('subCategory').child(keys.catId).child('products').child(keys.subId).remove()
   }

   addOrderToTable(keys,value){
     return db.child(keys.id).child('tables').child(keys.table).child('orders').push(value)
   }

   getTableWaiterStatus(keys){
     return db.child(keys.id).child('tables').child(keys.table).child('waiter')
   }

   updateTableWaiterStatus(keys,value){
     console.log(keys,value)
    return db.child(keys.id).child('tables').child(keys.table).update({'waiter':value})
  }

   getTableOrder(keys){
    
    return db.child(keys.id).child('tables').child(keys.tableId).child('orders')
    
   }

   updateTableOrder(keys,value){
    return db.child(keys.id).child('tables').child(keys.tableId).child('orders').child(keys.orderId).update(value)
   }

   clearTableOrder(keys){
    return db.child(keys.id).child('tables').child(keys.tableId).child('orders').remove()
   }

   addOrdersToRestaurant(keys,value){
    return db.child(keys.id).child('orders').push(value)
   }

   getOrdersFromRestaurant(keys,startDate,endDate){
     startDate = new Date(startDate)
     endDate = new Date(endDate)

     console.log(startDate,endDate)
     startDate = typeof startDate === "object"? startDate.setHours(0,0,0,0):startDate.setHours(0,0,0,0)
     endDate = typeof endDate === "object"? endDate.setHours(23,59,59,999):endDate.setHours(23,59,59,999)
     console.log(startDate,endDate)
    return db.child(keys.id).child('orders').orderByChild('tableClear').startAt(startDate).endAt(endDate)
   }

}

export default new RestaurantDataService();