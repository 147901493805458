import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import './inventory.scss'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    IconButton,
  } from '@mui/material';
import { Search } from '@mui/icons-material';
import InventoryDataService from '../../../firebase/inventory'

function Inventory(){
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [products, setProducts] = useState([]); // Initialize products state
    const navigate = useNavigate();
    const params = useParams();

    const handleSort = (field) => {
      if (field === sortedField) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortedField(field);
        setSortOrder('asc');
      }
    };

  useEffect(() => {
    const fetchData = async () => {
        
        InventoryDataService.getInventory(params).on('value',(item)=>{
            const arrayOfObjects = [];
            let inputArr = item.val()
            
            for (const key in inputArr) {
                if (inputArr.hasOwnProperty(key)) {
                    const innerObject = inputArr[key];
                    const objToAdd = { key, ...innerObject };
                    arrayOfObjects.push(objToAdd);
                }
            }
            console.log(inputArr,arrayOfObjects)
            setProducts(arrayOfObjects)
        })
    };
    
    fetchData();
    return ()=>{
        InventoryDataService.getInventory(params).off('value',(item)=>{
            const arrayOfObjects = [];
            let inputArr = item.val()
            
            for (const key in inputArr) {
                if (inputArr.hasOwnProperty(key)) {
                    const innerObject = inputArr[key];
                    const objToAdd = { key, ...innerObject };
                    arrayOfObjects.push(objToAdd);
                }
            }
            console.log(inputArr,arrayOfObjects)
            setProducts(arrayOfObjects)
        })
    }
  }, []); 
  
    const sortedData = products
      .filter((item) =>
        searchTerm
          ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.type.toLowerCase().includes(searchTerm.toLowerCase())
          : true
      )
      .sort((a, b) => {
        if (sortedField) {
          const aValue = a[sortedField];
          const bValue = b[sortedField];
          if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

      const checkInventory=(item)=>{
        const { name, quantity, minQuantity } = item;
      
        if (parseInt(quantity) >= parseInt(minQuantity)) {
          return "Enough";
        } else {
            const shortage = parseInt(minQuantity) - parseInt(quantity);
            const notificationMessage = `${name} is low in stock. It's ${shortage} units short.`;

    if ('Notification' in window) {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          new Notification('Inventory Alert', {
            body: notificationMessage,
          });
        }
      });
    }
          return "Low";
        }
      }
  
    return (
        <div className="Inventory">
        <div className="la-costa-fixed-watermark"></div>
       <div className="heading">
         <p>Inventory</p>
       </div>
        <TextField
          label="Search"
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('serialNumber')}>Serial Number</TableCell>
                <TableCell onClick={() => handleSort('name')}>Name</TableCell>
                <TableCell onClick={() => handleSort('type')}>Type</TableCell>
                <TableCell onClick={() => handleSort('quantity')}>Quantity</TableCell>
                <TableCell onClick={() => handleSort('price')}>Price</TableCell>
                <TableCell onClick={() => handleSort('totalCost')}>Total Cost</TableCell>
                <TableCell onClick={() => handleSort('status')}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row,index) => (
                <TableRow key={index+'-inventory-table'}>
                  <TableCell>{index+1}</TableCell>
                  <TableCell onClick={e=>{navigate('edit-product/'+row.key)}}>{row.name}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>₹ {row.price}</TableCell>
                  <TableCell>₹ {Math.round(row.quantity*row.price)}</TableCell>
                  <TableCell>{checkInventory(row)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="bottom-buttons">
            <div className={`float-menu active`} onClick={e=>{navigate('add-product')}}>
                {
                    <p>+</p>
                }
            </div>

            

           </div>
      </div>
   )
}

export default Inventory;