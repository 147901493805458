import  firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import 'firebase/compat/storage'


let config = {
  apiKey: "AIzaSyBAtNrYKYtmSdxtL-0sszubmua6fNSEwmA",
  authDomain: "la-carte-4fa10.firebaseapp.com",
  databaseURL: "https://la-carte-4fa10-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "la-carte-4fa10",
  storageBucket: "la-carte-4fa10.appspot.com",
  messagingSenderId: "801535196816",
  appId: "1:801535196816:web:58a5bfce1bb7c9d1e8a215",
  measurementId: "G-FTTNSNCHTJ"
  };
  
firebase.initializeApp(config);
export  const database =firebase.database();
export  const auth =firebase.auth();
export  const storage =firebase.storage();

export default firebase;