import { useNavigate } from "react-router";
import './footer.scss';

function Footer(){
    const navigate = useNavigate()
    return (
        <div className="Footer">
             <div className="footer-header" onClick={e=>{navigate('/')}}>La Carte</div>
             <div className="footer-links">
                 <div className="footer-link" onClick={e=>{navigate('/contact')}}>
                     Contact Us
                 </div>
                 <div className="footer-link" onClick={e=>{navigate('/refund')}}>
                     Refund and Cancellation Policy
                 </div>
                 {/* <div className="footer-link" onClick={e=>{navigate('/cancellation')}}>
                     Cancellation
                 </div>}
                 <div className="footer-link" onClick={e=>{navigate('/shipping')}}>
                     Shipping
                 </div> */}
                  <div className="footer-link" onClick={e=>{navigate('/login')}}>
                     Vendor Login
                 </div>
                 <div className="footer-link" onClick={e=>{navigate('/privacy')}}>
                     Privacy/Policy
                 </div>
                 <div className="footer-link" onClick={e=>{navigate('/terms-and-condition')}}>
                     Terms and Conditions
                 </div>
             </div>
             <div className="copyright">Made with ♥️ by <a href="https://witera.tech" target="_blank">WITERA</a></div>
        </div>
    )
}

export default Footer;