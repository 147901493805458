import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import './edit-item.scss'
import RestaurantDataService from '../../../firebase/restaurant'

function EditItem(){
    const [product,setProduct] = useState({
        name:'',
        description:'',
        price:'',
        veg:'',
        outOfStock:'',
    })

    const params = useParams();

    const navigate = useNavigate();

    useEffect(()=>{
        if(params.subId)
        RestaurantDataService.getRestaurantMenuItem(params).on('value',(item)=>{
            console.log(item.val())
            setProduct(item.val())
        })

        window.$('#root .bottom-tab').hide()
        return ()=>{
            window.$('#root .bottom-tab').show()
            if(params.subId)
            RestaurantDataService.getRestaurantMenuItem(params).off('value',(item)=>{
                console.log(item.val())
                setProduct(item.val())
            })
        }
    },[]);

    const changeProductValue = (e)=>{
        if(e.target.name!=='price'){
            setProduct({
                ...product,
                [e.target.name]:e.target.value
            })
        } else {
            setProduct({
                ...product,
                [e.target.name]:e.target.value
            })
        }
       console.log(product)
    }

    const deleteProduct = ()=>{
        RestaurantDataService.deleteRestaurantMenuItem(params)
        navigate('/admin/restaurant/'+params.id+'/menu')
    }

    const saveProduct = ()=>{
      let newproduct = product
      newproduct['price']=Number(newproduct['price'])+Number(newproduct['price']*0.1)
      if(params.subId)
      RestaurantDataService.editRestaurantMenuItem(params,newproduct)
      else
      RestaurantDataService.addRestaurantMenuItem(params,newproduct)

      navigate('/admin/restaurant/'+params.id+'/menu')
    }
    
    return (
        <div className="edit-item">
           <div className="la-costa-fixed-watermark"></div>

<p className="heading">{params.subId?'Update product':'Add product'}</p>

<div className="item-field">
    <label htmlFor="Category">Name</label>
    <input type="text" name="name" placeholder="Category name..." value={product?.name} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Price</label>
    <input type="number" name="price" placeholder="Price..." value={product?.price} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Description</label>
    <input type="text" name="description" placeholder="Description..." value={product?.description} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Veg</label>
    <select name="veg" value={product?.veg} onChange={e=>{changeProductValue(e)}}>
    <option value={''} disabled>Select vegetarian category</option>

        <option value={true} selected>Yes</option>
        <option value={false}>No</option>
    </select>
</div>

<div className="item-field">
    <label htmlFor="Category">Out of stock</label>
    <select name="outOfStock" value={product?.outOfStock} onChange={e=>{changeProductValue(e)}}>
    <option value={''} disabled>Select is out of stock</option>
        
        <option value={true}>Yes</option>
        <option value={false} selected>No</option>
    </select>
</div>


<div className="save-button" onClick={e=>{
    saveProduct()
}}>Save</div>

{
params.subId?
<div className="delete-button" onClick={e=>{
    deleteProduct()
}}>Delete</div>:''
}
        </div>
    )
} 

export default EditItem;