import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import './edit-inventory-item.scss'
import InventoryDataService from '../../../../firebase/inventory'

function EditInventoryItem(){
    const [product,setProduct] = useState({
        name: '',
        type: '',
         quantity: '',
          price: '', 
           minQuantity:''
    })

    const params = useParams();

    const navigate = useNavigate();

    useEffect(()=>{
        if(params.subId)
        InventoryDataService.getInventoryCategory(params).on('value',(item)=>{
            console.log(item.val())
            setProduct(item.val())
        })

        // window.$('#root .bottom-tab').hide()
        return ()=>{
            // window.$('#root .bottom-tab').show()
            if(params.subId)
            InventoryDataService.getInventoryCategory(params).off('value',(item)=>{
                console.log(item.val())
                setProduct(item.val())
            })
        }
    },[]);

    const changeProductValue = (e)=>{
        if(e.target.name!=='price'){
            setProduct({
                ...product,
                [e.target.name]:e.target.value
            })
        } else {
            setProduct({
                ...product,
                [e.target.name]:e.target.value
            })
        }
       console.log(product)
    }

    const deleteProduct = ()=>{
        InventoryDataService.deleteInventoryCategory(params)
        navigate('/admin/restaurant/'+params.id+'/inventory')
    }

    const saveProduct = ()=>{
      let newproduct = product
      if(params.subId)
      InventoryDataService.editInventoryCategory(params,newproduct)
      else
      InventoryDataService.addInventoryCategory(params,newproduct)

      navigate('/admin/restaurant/'+params.id+'/inventory')
    }
    
    return (
        <div className="edit-inventory-item">
           <div className="la-costa-fixed-watermark"></div>

<p className="heading">{params.subId?'Update product':'Add product'}</p>

<div className="item-field">
    <label htmlFor="Category">Name</label>
    <input type="text" name="name" placeholder="Product name..." value={product?.name} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Quantity</label>
    <input type="number" name="quantity" placeholder="Quantity..." value={product?.quantity} onChange={e=>{changeProductValue(e)}} />
</div>


<div className="item-field">
    <label htmlFor="Category">Min. Quantity</label>
    <input type="number" name="minQuantity" placeholder="Min. Quantity..." value={product?.minQuantity} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Price</label>
    <input type="number" name="price" placeholder="Price..." value={product?.price} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="item-field">
    <label htmlFor="Category">Type</label>
    <input type="text" name="type" placeholder="Type..." value={product?.type} onChange={e=>{changeProductValue(e)}} />
</div>

<div className="save-button" onClick={e=>{
    saveProduct()
}}>Save</div>

{
params.subId?
<div className="delete-button" onClick={e=>{
    deleteProduct()
}}>Delete</div>:''
}
        </div>
    )
} 

export default EditInventoryItem;