import {database} from "./firebase";

const db = database.ref("/restaurants");

class InventoryDataService {
  
  getInventory(keys) {
      console.log('keys',keys.id)
    return db.child(keys.id).child('inventory');
  }

  addInventoryCategory(keys,value){
    return db.child(keys.id).child('inventory').push(value)
  }
  
  editInventoryCategory(keys,value){
    return db.child(keys.id).child('inventory').child(keys.subId).update(value)
  }

  deleteInventoryCategory(keys){
    return db.child(keys.id).child('inventory').child(keys.subId).remove()
  }

  getInventoryCategory(keys){
    return db.child(keys.id).child('inventory').child(keys.subId)
  }

}

export default new InventoryDataService();