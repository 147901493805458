import './App.scss';
import Homepage from './components/homepage/homepage';
import Landingpage from './components/home/home';
import Restaurant from './components/restaurant/restaurant';
import Menu from './components/menu/menu'
import Cart from './components/cart/cart';
import Confirm from './components/confirm/confirm';
import { Routes, Route } from 'react-router-dom';
import Home from './components/restaurant-admin/home/home';
import RestaurantAdmin from './components/restaurant-admin/restaurant-admin';
import Profile from './components/restaurant-admin/profile/profile';
import RestaurantMenu from './components/restaurant-admin/menu/menu';
import EditCategory from './components/restaurant-admin/edit-category/edit-category';
import EditItem from './components/restaurant-admin/edit-item/edit-item';
import Login from './components/login/login';
import Scanner from './components/scanner/scanner';
import TableOrders from './components/restaurant-admin/table-orders/table-orders';
import firebase from './firebase/firebase';
import User from './firebase/user';
import { Navigate } from 'react-router';
import UserOrders from './components/user-orders/user-orders';
import RestaurantOrders from './components/restaurant-admin/orders/orders';
import Refund from './components/refund/refund';
import Term from './components/term/term';
import Policy from './components/policy/policy';
import Contact from './components/contact/contact';
import BusinessSite from './components/business-site/business-site';
import Cancellation from './components/cancellation/cancellation';
import Shipping from './components/shipping/shipping';
import RestaurantInsghts from './components/restaurant-admin/insights/insights';
import Inventory from './components/restaurant-admin/inventory/inventory';
import EditInventoryItem from './components/restaurant-admin/inventory/edit-inventory-item/edit-inventory-item';

const RequireAuth = ({ children,admin }) => {

  const userIsLogged = User.getUserId();

  
    if (!userIsLogged) {
       return <Navigate to={{
        pathname: "/login",
      }} 
      state={{
        newUrl :window.location.href.split(window.location.origin)[1].toString(),
        admin :admin
      }}
      />;
    }

    if(userIsLogged&&admin){
      User.isUserAdmin().on('value',function(item){
        let data =item.val()
        if(!data.isAdmin){
           User.signOut()
           return <Navigate to={{
            pathname: "/login",
          }} 
          state={{
            newUrl :window.location.href.split(window.location.origin)[1].toString(),
            admin:admin
          }}
          />;
        }
      })
    }
  
  return children;
};

function App() {
  return (
    <div className="App">
      <Routes>

           <Route path='/' element={<Landingpage/>} ></Route>
           <Route path='/order' element={<Homepage/>} ></Route>

           <Route path='/refund' element={<Refund/>} ></Route>
           <Route path='/contact' element={<Contact/>} ></Route>
           {/* <Route path='/cancellation' element={<Cancellation/>} ></Route> */}
           {/* <Route path='/shipping' element={<Shipping/>} ></Route> */}
           <Route path='/privacy' element={<Policy/>} ></Route>
           {/* <Route path='/shipping-policy' element={<Refund/>} ></Route> */}
           <Route path='/business-info' element={<BusinessSite/>} ></Route>


           <Route path='/terms-and-condition' element={<Term/>} ></Route>


           <Route path='admin/restaurant/:id/*' element={
            <RequireAuth admin={true}>
              <RestaurantAdmin/>
            </RequireAuth>
           } >
                <Route path='home' element={<Home/>}></Route>
                <Route path={`home/table-orders/:tableId`} element={<TableOrders/>}></Route>  
                <Route path={`orders`} element={<RestaurantOrders/>}></Route>  
                <Route path={`insights`} element={<RestaurantInsghts/>}></Route>  
                <Route path={`profile`} element={<Profile/>}></Route>
                <Route path={`menu`} element={<RestaurantMenu/>}></Route>
                <Route path={`inventory`} element={<Inventory/>}></Route>
                <Route path={`inventory/add-product`} element={<EditInventoryItem/>}></Route>
                <Route path={`inventory/edit-product/:subId`} element={<EditInventoryItem/>}></Route>
                <Route path={`menu/edit-category/:subId`} element={<EditCategory/>}></Route>  
                <Route path={`menu/add-category`} element={<EditCategory/>}></Route>  
                <Route path={`menu/:menuId/subCategory/:catId/edit-product/:subId`} element={<EditItem/>}></Route>  
                <Route path={`menu/:menuId/subCategory/:catId/add-product`} element={<EditItem/>}></Route>  
           </Route>

           <Route path='login' element={ <Login/>}></Route>
           <Route path='scanner' element={<Scanner/>} ></Route>

           <Route path='restaurant/:id' element={<Restaurant/>} ></Route>
           <Route path='menu/:id' element={<Menu/>} ></Route>
           <Route path='cart/:id' element={
             <RequireAuth admin={false}>
               <Cart/>
             </RequireAuth>
           } ></Route>
           <Route path='orders' element={
             <RequireAuth admin={false}>
               <UserOrders/>
             </RequireAuth>
           } ></Route>
           <Route path='confirm/:id' element={<Confirm/>} ></Route>

        </Routes>
    </div>
  );
}

export default App;
