import { useEffect, useState } from "react";
import './profile.scss';
import RestaurantImage from '../../../assets/rest.png';
import ClockImage from '../../../assets/clock.svg';
import { useNavigate, useParams } from "react-router";
import RestaurantDataService from '../../../firebase/restaurant'
import User from '../../../firebase/user'

function Profile(){

    const navigate = useNavigate();
    const [edit,setEdit] = useState(false);
    const [restaurant,setRestaurant] = useState({});
    let { id } = useParams();

    useEffect(()=>{
        RestaurantDataService.get(id).on('value',dataChange)
        return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
            setRestaurant({...data.val(),key:data.key})
    }


    return (
        <div className="Profile">
              <div className="restaurant-image"
              style={{
                background:`url(${restaurant.profilePic})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover'
            }}
              >
            </div>
            <div className="restaurant-details">
                <div className="restaurant-desc">
                    {
                        edit?
                        <div className="edit-name">
                            <input type="text" value={restaurant.name} />
                        </div>:
                        <p className="name">{restaurant.name}</p>
                    }
                    {
                        !edit?
                        <div className="time">
                        <img src={ClockImage} alt="time" />
                        {restaurant.openingTime} - {restaurant.closingTime}</div>:
                        <div className="d-flex">
                            <div className="start-time">
                                <label htmlFor="">Start time</label>
                                <input type="time" step="1" value={restaurant.startTime} className="form-control" placeholder="Start time"  />
                            </div>
                            <div className="close-time">
                                <label htmlFor="">Close time</label>
                                <input type="time" step="1" value={restaurant.closeTime} className="form-control" placeholder="Close time"  />
                            </div>
                        </div>
                    }
                    {
                        edit?
                        <div className="edit-name">
                            <textarea type="text" value={restaurant.description} />
                        </div>:
                        <p className="desc">{restaurant.description} </p>
                    }
                    <div className="logout"
                        onClick={e=>{
                            navigate('/')
                            User.signOut()
                        }}
                    >Logout</div>
                </div>
            </div>
        </div>
    )
}

export default Profile;