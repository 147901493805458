import RestaurantImage from '../../assets/rest.png';
import ClockImage from '../../assets/clock.svg';
import RestaurantDataService from '../../firebase/restaurant';

import './restaurant.scss';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';

function Restaurant(){
    const [restaurantDetails,setRestaurantDetails] = useState({})
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(()=>{
        RestaurantDataService.get(id).on('value',dataChange)
        return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
            setRestaurantDetails({...data.val(),key:data.key})
    }
    

    return (
        <div className="restaurant-page">
            <div className="restaurant-image" style={{
                background:`url(${restaurantDetails.profilePic})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover'
            }}>
            </div>
            <div className="restaurant-details">
                <div className="restaurant-stats">
                    <div className="restaurant-tag">
                     <p>Popular</p>
                    </div>
                </div>
                <div className="restaurant-desc">
                    <p className="name">{restaurantDetails.name}</p>

                    <div className="time">
                        <img src={ClockImage} alt="time" />
                        {restaurantDetails.openingTime} - {restaurantDetails.closingTime}</div>
                    <p className="desc">{restaurantDetails.description}</p>
                </div>
                <div className="menu" onClick={e=>{navigate('/menu/'+restaurantDetails.key+'?table=zdcwe235')}}>Menu</div>
            </div>
        </div>
    )
}


export default Restaurant;