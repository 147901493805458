import { useEffect, useState } from "react";
import './orders.scss';
import NonVegIcon from '../../../assets/veg.svg';
import VegIcon from '../../../assets/non-veg.svg';
import RestaurantDataService from '../../../firebase/restaurant'
import { useParams } from "react-router";
import Moment from 'react-moment';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { Printer, Text as ThermalText, Row, Line, QRCode, render } from 'react-thermal-printer';
import { PDFDownloadLink, Document, Page, Text , View, StyleSheet, Link,Image } from '@react-pdf/renderer';
import { style } from "@mui/system";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    justifyContent:"space-between"
  },
  tableHeader: {
    fontWeight: 'bold',
    flex: 1,
  },
  tableCell: {
    flex: 1,
    fontWeight:'light',
    fontSize:12
  },
  content: {
    fontSize: 12,
    marginBottom: 10,
  },
  totalRow: {
    borderTopColor: '#000',
    borderTopWidth: 1,
  },
});


function RestaurantOrders(){

    const [orders,setOrders] = useState([])
    const [ordersCopy,setOrdersCopy] = useState([])
    const [restaurantDetails,setRestaurantDetails] = useState({})
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ]);
  


    const params = useParams();

    const filterOrders = (e)=>{
       let newArray = ordersCopy.filter((item,index)=>item.key.includes(e.target.value))
      
       setOrders(newArray)
    }

    useEffect(()=>{
      RestaurantDataService.get(params.id).on('value',resDataChange)
      RestaurantDataService.getOrdersFromRestaurant(params,Date.now(),Date.now()).on('value',dataChange)
      return ()=>{
      RestaurantDataService.getOrdersFromRestaurant(params,Date.now(),Date.now()).off('value',dataChange)
      RestaurantDataService.get(params.id).off('value',resDataChange)
      }
    },[])

    const resDataChange = (data) =>{
      console.log({...data.val(),key:data.key},'restaurant details')
      setRestaurantDetails({...data.val(),key:data.key})
    }

    const dataChange = (item)=>{
      console.log(item.val(),'restaurant orders')
      let prods  = []
      let values = item.val()

      Object.keys(item.val()).forEach((item)=>{
        let table = values[item]['fromTable']
        delete values[item]['fromTable']
        delete values[item]['tableClear']
        Object.keys(values[item]).forEach((element)=>{
          let order = values[item][element]
          console.log('value',table,order)
          prods.push(order)
         })
      })

      console.log(prods,'restaurant order value')

      setOrders(prods)
      setOrdersCopy(prods)
    }

    const getSubPrice = (val)=>{
      let ans =0;
      if(val)
      Object.keys(val).forEach((item)=>{
        ans+=(val[item].price*val[item].quantity)
      })
      return ans;
    }

    const filterOrderDate = (value)=>{
      console.log(value.selection)
      RestaurantDataService.getOrdersFromRestaurant(params,value.selection.startDate,value.selection.endDate).on('value',dataChange)
      setState([value.selection])
    }

    const print = async (products,paymentMethod) =>{
      const billContent = (
        <Printer type="epson" width={42}>
    <ThermalText align="center">{restaurantDetails.name}</ThermalText>
    <ThermalText align="center">{new Date().toLocaleString()}</ThermalText>
    <Line />
    <Row
        left={`Name`}
        middle={`Quantity`}
        right={`Price(₹)`}
      />
    {Object.keys(products).map((productKey) => (
      <Row
        key={products[productKey].name}
        left={`${products[productKey].name}`}
        middle={`${products[productKey].quantity}`}
        right={`₹${Math.floor(products[productKey].price * products[productKey].quantity)}`}
      />
    ))}
    <Line />
    <Row left="SGST (5%)" right={`₹${restaurantDetails.register?(getSubPrice(products)*0.05).toFixed(2):0}`} />
    <Row left="CGST (5%)" right={`₹${restaurantDetails.register?(getSubPrice(products)*0.05).toFixed(2):0}`} />
    <Line />
    <Row left="Total" right={`₹${getSubPrice(products)+(restaurantDetails.register?((getSubPrice(products)*0.1)+getSubPrice(products)*0.05).toFixed(2):0)}`} />
    <ThermalText align="center">Payment: {'UPI'}</ThermalText>
    <ThermalText align="center">Thank you for dining with us!</ThermalText>
    <ThermalText align="center">Visit us again!</ThermalText>
    <QRCode align="center" content={"https://thelacarte.com/restaurant/"+params.id} />
        </Printer>
      );
      console.log(billContent,'bill')
      const data = await render(billContent);
      const port = await window.navigator.serial.requestPort();
  try {
    await port.open({ baudRate: 9600 });
    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
      console.log("Printing completed successfully");
    }
    await port.close(); 
  } catch (error) {
    console.error("Error while printing:", error);
    if (port && port.readable) {
      await port.close();
    }
  } 
    }

    const pdfFormat = (products,paymentMethod,orderId)=>{

      return (
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <Text style={styles.title}>{restaurantDetails.name}</Text>
                <Text style={{...styles.link,textAlign:'center',marginBottom:'100px'}}>
                  <Link src={`https://thelacarte.com/restaurant/${12345645}`} style={styles.link}>
                  Visit us again
                  </Link>
                </Text>
                <View style={{...styles.content,marginHorizontal:20,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'space-between'}}>
                  <Text>
                    Order Id: {orderId}
                  </Text>
                  <Text>
                  {new Date().toLocaleString()}
                  </Text>
                  </View>
              </View>
              <View style={{...styles.table,marginBottom:'100px'}}>
                <View style={{...styles.tableRow,backgroundColor:'lightgray'}}>
                  <Text style={styles.tableHeader}>Name</Text>
                  <Text style={styles.tableHeader}>Quantity</Text>
                  <Text style={styles.tableHeader} >Price (Rupees)</Text>
                </View>
                {Object.keys(products).map((productKey) => (
                  <View style={styles.tableRow} key={products[productKey].name}>
                    <Text style={styles.tableCell}>{products[productKey].name}</Text>
                    <Text style={styles.tableCell}>{products[productKey].quantity}</Text>
                    <Text style={styles.tableCell}> {products[productKey].price}</Text>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>CGST</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {
                      (restaurantDetails.register
                        ? ( getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>SGST</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {
                      (restaurantDetails.register
                        ? ( getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>Total</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {getSubPrice(products) +
                      (restaurantDetails.register
                        ? (getSubPrice(products) * 0.1 + getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
              </View>
              <Text style={{...styles.content,textAlign:'center'}}>Payment: {paymentMethod?paymentMethod:'UPI'}</Text>
              <Text style={{...styles.content,textAlign:'center'}}>Thank you for dining with us!</Text>
              <Text style={{...styles.content,textAlign:'center'}}>POWRED BY LACARTE.COM</Text>
            </Page>
          </Document>
  
      )
    }

    return (
        <div className="Orders">
           <div className="la-costa-fixed-watermark"></div>
           <div className="heading">
             <p>Order history</p>
           </div>
  <DateRange
  editableDateInputs={false}
  onChange={item => {
    filterOrderDate(item)
  }}
  moveRangeOnFirstSelection={false}
  showSelectionPreview={false}
  showMonthAndYearPickers={false}
  ranges={state}
  months={1}
/>

           <div className="order-filters">
              <input hidden type="text" placeholder="What do you want to order?" onChange={e=>{filterOrders(e)}}/>  
           </div>

           <div className="order-lists">

             {
              orders.map((item,index)=>{
                 return (
                  <div className="order-list">
                      <div className="orderId">Order: {item.key} {item.products?<>
                        <PDFDownloadLink
        document={
             pdfFormat(item.products,item.paymentMethod,item.key)
        }
        fileName="merchant_invoice.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Generating PDF...' : 'Download PDF'
        }
      </PDFDownloadLink>
                      </>:''} {item.products?<span style={{float:'right',cursor:'pointer'}} onClick={e=>{print(item.products,item.paymentMethod)}}>Print</span>:''}</div>
                      <div className="product-detail">
                        {
                         item.products? Object.keys(item.products).map((el)=>{
                            return (
                        <div className="product-row">
                          <div className="product-flex">
                            <div className="product-icon">
                              <img src={item.products[el].veg==='true'||item.products[el].veg===true?VegIcon:NonVegIcon} alt="" />
                            </div>
                            <p className="product-name">{item.products[el].name} x {item.products[el].quantity}</p>
                          </div>
                          <p className="product-prices">₹ {item.products[el].price}</p>
                        </div>
                            )
                          }):null
                        }
                      </div>

                      <div className="bill-details">
                      <p className="price">Item Total<span>₹ {getSubPrice(item.products)}</span>
                      </p>
                      {/* <p className="price">Platform fees <span>₹ {getSubPrice(item.products)*0.1}</span></p> */}
                      {
                        restaurantDetails.register?
                        <p className="price">Taxes <span>₹ {getSubPrice(item.products)*0.05}</span>
                      </p>:''
                      }
                      <div className="total">
                        <p className="merchant">   <Moment parse="YYYY-MM-DD HH:mm">
               {new Date(item.time)}
            </Moment></p>
                        <p className="bill">Bill total<span>₹ {getSubPrice(item.products)+(restaurantDetails.register?((getSubPrice(item.products)*0.1)+getSubPrice(item.products)*0.05).toFixed(2):0)}</span></p>
                      </div>
                      </div>

                  </div>
                 )
               })
             }

  
           </div>
        </div>
    )
}

export default RestaurantOrders;