import { useEffect, useState } from "react";
import './cart.scss';
import deleteIcon from '../../assets/delete.svg'
import { useNavigate, useParams } from "react-router";
import CartDataService from '../../firebase/cart';
import RestaurantDataService from '../../firebase/restaurant';
import User from "../../firebase/user";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import user from "../../firebase/user";

function Cart(){
    const [cartList,setCartList] = useState([]);
    const [cartListFlag,setCartListFlag] = useState([]);
    const [restaurantDetails,setRestaurantDetails] = useState({})
    const [placeOrder,setPlaceorder] = useState(false)

    const { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();


    useEffect(()=>{
      let cart = CartDataService.getCart()
      let data = []
      Object.keys(cart).forEach(item=>{
          data.push(cart[item])
      })
      setCartList(data)
      RestaurantDataService.get(id).on('value',dataChange)
      return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
      }
    },[])

    const dataChange = (data)=>{
      console.log({...data.val(),key:data.key},'restaurant details')
      setRestaurantDetails({...data.val(),key:data.key})
    }

    useEffect(()=>{
        let cart = CartDataService.getCart()
        let data = []
        Object.keys(cart).forEach(item=>{
            data.push(cart[item])
        })
        setCartList(data)
    },[cartListFlag])

    const navigate = useNavigate();

    const addToCart = (item) =>{
        setCartListFlag(!cartListFlag)
        CartDataService.addToCart(item)
    }

    const removeFromCart = (item) =>{
        setCartListFlag(!cartListFlag)
        CartDataService.removeToCart(item)
    }

    const deleteFromCart = (id) =>{
        setCartListFlag(!cartListFlag)
        CartDataService.deleteCartItem(id)
    }

    const getTotalPrice=()=>{
      let price = 0;
      cartList.forEach((item)=>{
          price+=(item.quantity*item.price)
      })
      return price
    }

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    const createOrderId = async ()=>{
      setPlaceorder(true)
      if(Number(Number(getTotalPrice()+(restaurantDetails.registered?getTotalPrice()*0.05:0)).toFixed(2))>0){
        
        try {
          
          const url = 'https://us-central1-la-carte-4fa10.cloudfunctions.net/api/payment/phonepe';
          const requestBody = {
            mobileNumber: user.getUserNumber(),
            amount: Number(Number(getTotalPrice()+(restaurantDetails.registered?getTotalPrice()*0.05:0)).toFixed(2))*100, 
            callbackUrl:window.location.href, 
            redirectUrl:window.location.origin+'/confirm/'+id+'?table='+searchParams.get('table'),
            merchantUserId:User.getUserId(),
          };
    
          const response = await axios.post(url, requestBody);
          console.log(response,'response')

          if(response.data.data.data.instrumentResponse.redirectInfo.url){
            localStorage.setItem('phonepe_transactionId',response.data.data.data.merchantTransactionId)
            window.open(response.data.data.data.instrumentResponse.redirectInfo.url);
          }
          

        } catch (error) {
          console.error(error);
          // Handle error
        }
      
      } else {
        alert('Cart price should be greater than 25 rupees')
      }
        
      
      
    }

    const razor = async (oId) => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
  
      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
        return;
      }
  
      console.log('order id',oId)
      const options = {
        key: "rzp_live_nM8s7KEEqphmCL",
        currency: "INR",
        amount: Number((Number(getTotalPrice()+(restaurantDetails.registered?getTotalPrice()*0.05:0).toFixed(2)))) * 100,
        name: "La carte",
        description: "Thanks for purchasing",
        receipt:'restaurant_'+id,
        order_id:oId,
        image:
          "https://i.imgur.com/q9Zmm9L_d.webp",
  
        handler: function (response) {
            console.log('response',response)
            placeMyOrder(response.razorpay_payment_id)
        },
        prefill: {
          name: "",
          contact:User.getUserNumber()
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }


    const placeMyOrder = (pId,pStatus)=>{

        let order = {
            products:convertArrayToObject(cartList,'key'),
            time:Date.now(),
            totalPrice:Number(getTotalPrice()+(restaurantDetails.registered?getTotalPrice()*0.05:0)).toFixed(2),
            user:User.getUserId(),
            status:'',
            paymentId:pId,
            paymentStatus:pStatus,
            restaurantId:id
        }
        
        let keys = {
            id:id,
            table:searchParams.get('table')
        }

        User.addOrderToUserDetails(order)
        CartDataService.deleteCartItems()
        RestaurantDataService.addOrderToTable(keys,order)
        navigate('/confirm/'+id+'?table='+searchParams.get('table'))
    }

    
    const loadScript = (src) => {
        return new Promise((resovle) => {
          const script = document.createElement("script");
          script.src = src;
    
          script.onload = () => {
            resovle(true);
          };
    
          script.onerror = () => {
            resovle(false);
          };
    
          document.body.appendChild(script);
        });
      }


    return (
        <div className="Cart la-costa-watermark">
           <p className="cart-heading">Order details</p>
           <div className="cart-lists">
                {
                   cartList.length?
                   cartList.map((item)=>{
                      return (
               <div className="cart-list" key={item.key+'-cart'}>
                   <div className="item-detail">
                       <div className="item-name">
                           <p className="name">{item.name}</p>
                           {/* <p className="category">{item.description.slice(0,15)+'...'}</p> */}
                           <p className="item-price">₹ {item.price}</p>
                       </div>

                       <div className="item-update">
                            <button onClick={e=>removeFromCart(item)} className="item-minus">-</button>
                            <p className="item-quantity">{item.quantity}</p>
                            <button onClick={e=>addToCart(item)} className="item-plus">+</button>
                       </div>
                   </div>
                   <div className="item-delete">
                       <button onClick={e=>deleteFromCart(item.key)}><img src={deleteIcon} alt="delete item" /></button>
                   </div>
               </div>
                      )
                   }) : 
                   <div className="back-to-menu" onClick={e=>navigate('/menu/'+id+'?table='+searchParams.get('table'))}>Back to menu</div>
                }
           </div>

           {

               cartList.length?

           <div className="place-order">
               <div className="place-order-content">
                   <p>Sub-total <span>₹ {Number(getTotalPrice()).toFixed(2)}</span></p>
                   {
                     restaurantDetails.registered?
                   <> 
                   <p>SGST <span>₹ {Number(getTotalPrice()*0.025).toFixed(2)}</span></p>
                   <p>CGST <span>₹ {Number(getTotalPrice()*0.025).toFixed(2)}</span></p>
                   </>:null
                   }

                   {/* <p>Discount<span>- ₹ 20</span></p> */}
                   <p className="total">Total <span>₹ {Number(getTotalPrice()+(restaurantDetails.registered?getTotalPrice()*0.05:0)).toFixed(2)}</span></p>
               </div>
               <button disabled={placeOrder} className="place-order-button" onClick={e=>{
                   createOrderId()
                   }}>
                   <p>
                   {!placeOrder?'Place my order':'Please wait...'}
                   </p>
                </button>

           </div>:''
           }

        </div>
    )
}

export default Cart;