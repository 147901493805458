import { useEffect, useState } from "react";
import './confirm.scss';
import confirmIcon from '../../assets/confirm.svg';
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import CartDataService from '../../firebase/cart';
import RestaurantDataService from '../../firebase/restaurant';
import User from "../../firebase/user";

function Confirm(){
    const [cartList,setCartList] = useState([]);
    const navigate = useNavigate()
    const {id} = useParams();
    const [searchParams,setSearchParams] = useSearchParams();
    const [status,setStatus] = useState(false)
    const [paymentResponse,setPaymentResponse] = useState({})
    
    useEffect(()=>{
        let cart = CartDataService.getCart()
        let data = []
        Object.keys(cart).forEach(item=>{
            data.push(cart[item])
        })
        console.log('cart list',data,convertArrayToObject(data,'key'))
        setCartList(data)
        let transactionId = localStorage.getItem('phonepe_transactionId')
        checkStatus(transactionId,data)
    },[])

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
    };

    const checkStatus = async (transactionId,cartData) =>{
        let body = {
            merchantTransactionId: transactionId
        }
        const response = await axios.post('https://us-central1-la-carte-4fa10.cloudfunctions.net/api/payment/status',body)
        console.log(response, response.success)

        setPaymentResponse(response)
        localStorage.removeItem('phonepe_transactionId')
        if(response.data.data.success){
           setStatus(true)
           placeMyOrder(response,cartData)
        } else {
            setStatus(true)
        }
    }

    const placeMyOrder = (response,cartData)=>{

        let order = {
            products:convertArrayToObject(cartData,'key'),
            time:Date.now(),
            totalPrice:Number(response.data.data.data.amount)/100,
            user:User.getUserId(),
            status:'',
            paymentId:response.data.data.data.merchantTransactionId,
            paymentStatus:response.data.data.success,
            paymentMethod:'UPI',
            restaurantId:id
        }
        
        let keys = {
            id:id,
            table:searchParams.get('table')
        }

        User.addOrderToUserDetails(order)
        CartDataService.deleteCartItems()
        RestaurantDataService.addOrderToTable(keys,order)
        navigate('/confirm/'+id+'?table='+searchParams.get('table'))
    }

    

    return (
        <div className="Confirm la-costa-watermark">
             <div className="confirm-content">
                 <img src={confirmIcon} alt="confirm" />
                 <p className="heading">{status?paymentResponse.data.data.code:'Processing'}</p>
                 <p className="text">{status?paymentResponse.data.data.message:'PLease wait...'}</p>
             </div>
             <div className="back-to-menu" onClick={e=>{status?navigate('/menu/'+id+'?table='+searchParams.get('table')):navigate('/cart/'+id+'?table='+searchParams.get('table'))}}>{status?'Explore':'Back to cart'}</div>
        </div>
    )
}

export default Confirm;