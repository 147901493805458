import Footer from '../footer/footer';
import './refund.scss';

const Refund=()=>{
     return (
         <>
         <div className="Refund">
             <div className="la-costa-fixed-watermark"></div>
<div className="container mt-5">
  <div className="row">
    <div className="col-md-8 offset-md-2">
    {/* <h1>QR-Based Food Ordering Application - Cancellation and Refund Policy</h1> */}
	<p>Thank you for using LaCarte! We hope you enjoy the convenience of ordering your favorite meals from your table. However, if you need to cancel your order or request a refund, please review our policy below:</p>
  <h2>Cancellation Policy:</h2>
	<p>Orders can be cancelled anytime before they are confirmed by the restaurant.</p>
	<p>If an order is cancelled after it has been confirmed by the restaurant, the cancellation will not be accepted.</p>
	<p>In case of a cancellation due to any technical issue with the application or payment gateway, the amount paid will be refunded to the customer within 5-7 working days.</p>
	<h2>Refund Policy:</h2>
	<p>If the customer is not satisfied with the quality of the food, the restaurant will take appropriate action to address the issue. In such cases, the customer may be eligible for a full or partial refund, depending on the situation.</p>

<p>Refunds will be issued to the same payment method used for the original transaction.</p>

<p>If there is any issue the customer/vendor can write to us at <a href="mailto:lacartethe@gmail.com">lacartethe@gmail.com</a></p>


    </div>
  </div>
</div>
         </div>
         <Footer></Footer>
         </>
     )
}

export default Refund;