import { useEffect, useState } from "react";
import './user-orders.scss'
import NonVegIcon from '../../assets/veg.svg';
import VegIcon from '../../assets/non-veg.svg';
import { useParams } from "react-router";
import User from "../../firebase/user";
import Moment from 'react-moment';
import { PDFDownloadLink, Document, Page, Text , View, StyleSheet, Link,Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    justifyContent:"space-between"
  },
  tableHeader: {
    fontWeight: 'bold',
    flex: 1,
  },
  tableCell: {
    flex: 1,
    fontWeight:'light',
    fontSize:12
  },
  content: {
    fontSize: 12,
    marginBottom: 10,
  },
  totalRow: {
    borderTopColor: '#000',
    borderTopWidth: 1,
  },
});
function UserOrders(){

    const [orders,setOrders] = useState([])
    const [ordersCopy,setOrdersCopy] = useState([])

    const params = useParams();

    const filterOrders = (e)=>{
       let newArray = ordersCopy.filter((item,index)=>item.key.includes(e.target.value))
       setOrders(newArray)
    }

    useEffect(()=>{
      User.getUserOrders().on('value',dataChange)
      return ()=>{
        User.getUserOrders().off('value',dataChange)
      }
    },[])

    const dataChange = (item)=>{
      console.log(item.val(),'orders')
      let prods  = []
      let values = item.val()

      Object.keys(item.val()).forEach((item)=>{
               let order = values[item]
               order['key'] = item
               prods.push(order)
      })

      console.log(prods)

      setOrders(prods)
      setOrdersCopy(prods)
    }

    const getSubPrice = (val)=>{
      let ans =0;
      if(val)
      Object.keys(val).forEach((item)=>{
        ans+=(val[item].price*val[item].quantity)
      })
      return ans;
    }

    const pdfFormat = (products,paymentMethod,orderId)=>{

      return (
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <Text style={styles.title}>{'LAKE VIEW CAFE'}</Text>
                <Text style={{...styles.link,textAlign:'center',marginBottom:'100px'}}>
                  <Link src={`https://thelacarte.com/restaurant/${params.id}`} style={styles.link}>
                  Visit us again
                  </Link>
                </Text>
                <View style={{...styles.content,marginHorizontal:20,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'space-between'}}>
                  <Text>
                    Order Id: {orderId}
                  </Text>
                  <Text>
                  {new Date().toLocaleString()}
                  </Text>
                  </View>
              </View>
              <View style={{...styles.table,marginBottom:'100px'}}>
                <View style={{...styles.tableRow,backgroundColor:'lightgray'}}>
                  <Text style={styles.tableHeader}>Name</Text>
                  <Text style={styles.tableHeader}>Quantity</Text>
                  <Text style={styles.tableHeader} >Price (Rupees)</Text>
                </View>
                {Object.keys(products).map((productKey) => (
                  <View style={styles.tableRow} key={products[productKey].name}>
                    <Text style={styles.tableCell}>{products[productKey].name}</Text>
                    <Text style={styles.tableCell}>{products[productKey].quantity}</Text>
                    <Text style={styles.tableCell}> {products[productKey].price}</Text>
                  </View>
                ))}
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>CGST</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {
                      (false
                        ? ( getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>SGST</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {
                      (false
                        ? ( getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>Total</Text>
                  <Text style={styles.tableCell}></Text>
                  <Text style={styles.tableCell}>
                  
                    {getSubPrice(products) +
                      (false
                        ? (getSubPrice(products) * 0.1 + getSubPrice(products) * 0.05).toFixed(2)
                        : 0)}
                  </Text>
                </View>
              </View>
              <Text style={{...styles.content,textAlign:'center'}}>Payment: {paymentMethod?paymentMethod:'UPI'}</Text>
              <Text style={{...styles.content,textAlign:'center'}}>Thank you for dining with us!</Text>
              <Text style={{...styles.content,textAlign:'center'}}>POWRED BY LACARTE.COM</Text>
            </Page>
          </Document>
  
      )
    }

    return (
        <div className="Orders">
           <div className="la-costa-fixed-watermark"></div>
           <div className="heading">
             <p>Order history</p>
           </div>
           <div className="order-filters">
              <input hidden type="text" placeholder="What do you want to order?" onChange={e=>{filterOrders(e)}}/>  
           </div>

           <div className="order-lists">

             {
              orders.map((item,index)=>{
                if(item)
                 return (
                  <div className="order-list" key={index}>

                      <div className="orderId">Order: {item.key}
                      {item.products?<>
                        <PDFDownloadLink
        document={
             pdfFormat(item.products,item.paymentMethod,item.key)
        }
        fileName="merchant_invoice.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Generating PDF...' : 'Download PDF'
        }
      </PDFDownloadLink>
                      </>:''}
                      </div>

                      <div className="product-detail">
                        {
                          item.products?Object.keys(item?.products).map((el)=>{
                            return (
                        <div className="product-row">
                          <div className="product-flex">
                            <div className="product-icon">
                              <img src={item.products[el].veg==='true'||item.products[el].veg===true?VegIcon:NonVegIcon} alt="" />
                            </div>
                            <p className="product-name">{item.products[el].name} x {item.products[el].quantity}</p>
                          </div>
                          <p className="product-prices">₹ {item.products[el].price}</p>
                        </div>
                            )
                          }):null
                        }
                      </div>

                      <div className="bill-details">
                      <p className="price">Item Total<span>₹ {getSubPrice(item.products)}</span>
                      </p>
                      <div className="total">
                        <p className="merchant">
                        <Moment parse="YYYY-MM-DD HH:mm">
               {new Date(item.time)}
            </Moment>
                        </p>
                        <p className="bill">Bill total<span>₹ {getSubPrice(item.products)}</span></p>
                      </div>
                      </div>

                  </div>
                 )
               })
             }

  
           </div>
        </div>
    )
}

export default UserOrders;