import Footer from '../footer/footer';
import './contact.scss';

function Contact(){
    return (
        <>   
        <div className="Contact">
            <div className="la-costa-fixed-watermark"></div>
            <h1>Contact Details</h1>
		
<div className="contact-info">
		<i className="fas fa-briefcase"></i>
		<span>LaCarte</span>
	</div>
	<div className="contact-info">
	<i className="fas fa-envelope" style={{opacity:"0"}}></i>

		<span>GSTIN: 08BBWPN8094F1ZT</span>
	</div>
	<div className="contact-info">
		<i className="fas fa-envelope"></i>
		<span>lacartethe@gmail.com</span>
	</div>
	<div className="contact-info">
		<i className="fas fa-phone"></i>
		<span>+91 7014202898 / +91 8266849356</span>
	</div>
	<div className="contact-info">
		<i className="fas fa-map-marker-alt"></i>
		<span>F-53, <br /> Samrat Prithviraj Chauhan Marg, <br />
		Chandarwardai Nagar, Ajmer, <br />  Rajasthan, 305001</span>
	</div>
        </div>
        <Footer></Footer>
        </>
    )
}

export default Contact;