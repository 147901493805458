import { useEffect } from "react";
import { Outlet, Route, Routes, useParams } from "react-router";
import BottomTab from "./bottom-tab/bottom-tab";
import './restaurant-admin.scss';

function RestaurantAdmin(){

    const {id } = useParams();
    useEffect(()=>{
    },[])
    return (
        <div className="restaurant-admin">
            <Outlet></Outlet>
            <BottomTab></BottomTab>
        </div>
    )
}

export default RestaurantAdmin;