import { useEffect, useState } from "react";
import './login.scss'
import Logo from '../../assets/logo.png';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import firebase from '../../firebase/firebase'
import User from "../../firebase/user";
import { useLocation, useNavigate } from "react-router";
import user from "../../firebase/user";

function Login(props){
    const navigate = useNavigate();
    const location = useLocation();

    const uiConfig = {
        signInOptions: [
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                  type: 'image', 
                  size: 'normal', 
                  badge: 'bottomleft' 
                },
                defaultCountry: 'IN', 
              }
        ],
        
        callbacks: {
            signInSuccessWithAuthResult: function(currentUser, credential, redirectUrl) {
                localStorage.setItem('uid',currentUser['user']['multiFactor']['user']['uid'])
                var userNumber = currentUser['user']['multiFactor']['user']['phoneNumber']
                localStorage.setItem('number',userNumber)

                if(currentUser['additionalUserInfo'].isNewUser){
                    User.addUserDetails(userNumber)
                }

                if(location?.state?.newUrl){
                    // if user redirected to login page
                    if(!location.state.admin){
                        // if link is not for admin
                       navigate(location.state.newUrl)
                    } else {
                        // if link is for admin check if user is admin
                        User.isUserAdmin().on('value',function(item){
                            let data = item.val()
                            console.log(data)
                            if(data.isAdmin){
                                // user is admin redirected
                               navigate(location.state.newUrl)
                            } else {
                                // ghost alert
                                navigate('/')
                                alert("You are not an admin please contact to spport team for more details!!!")
                            }
                        })
                    }   
                } else {
                    // if user directly come from login page
                    User.isUserAdmin().on('value',function(item){
                        let data = item.val()
                        console.log(data)
                        if(data.isAdmin){
                           navigate('/admin/restaurant/'+data.restaurantId+'/home')
                        } else {
                            navigate('/')
                        }
                    })
                }

              }.bind(this)
        }
    }
    

  useEffect(()=>{
    
      var ui = ''
    if(firebaseui.auth.AuthUI.getInstance()) {
        ui = firebaseui.auth.AuthUI.getInstance()
    } else {
        console.log('that one')

         ui = new firebaseui.auth.AuthUI(firebase.auth())
        }
        ui.start('#firebaseui-auth-container', uiConfig)

      return ()=>{
          ui?.reset()
      }
                
  },[firebase.auth()])

  useEffect(()=>{
     console.log(location,'location')
  },[])

    

    return (
        <div className="login">
            <div className="la-costa-fixed-watermark"></div>
            <div className="logo" onClick={e=>{navigate('/')}}>
                <img src={Logo} alt="" />
            </div>
            <div className="login-heading">Sign In</div>
           <div id={"firebaseui-auth-container"}></div>

            
        </div>
    )
}

export default Login;