import {auth} from '../firebase/firebase';
import {database} from '../firebase/firebase';


const user = auth;
const db = database.ref('/users');

class User{
    getUserId(){  
        return user?.currentUser?.uid||localStorage.getItem('uid');
    }

    getUserDetails(){
        return db.child(this.getUserId());
    }

    signOut(){
        localStorage.removeItem('uid')
        localStorage.removeItem('number')
        return user.signOut();
    }

    getUserNumber(){
         let value = localStorage.getItem('number');
         var number = value.replace(/\D/g, '').slice(-10);
         return number
    }

    addUserDetails(phone){
        let user = {
            key:this.getUserId(),
            isAdmin:false,
            restaurantId:'',
            phone:phone,
            orders:{}
        }
        return db.child(this.getUserId()).update(user)
    }

    addOrderToUserDetails(value){
        return db.child(this.getUserId()).child('orders').push(value)
    }

    getUserOrders(){
        return db.child(this.getUserId()).child('orders')
    }

    isUserAdmin(){
        return db.child(this.getUserId())
    }
}

export default new User();