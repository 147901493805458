import { useEffect } from "react";
import './bottom-tab.scss';
import HomeIcon from '../../../assets/Home.svg';
import ProfileIcon from '../../../assets/Profile.svg';
import MenuIcon from '../../../assets/menu.png';
import OrdersIcon from '../../../assets/orders.png';
import InsightsIcon from '../../../assets/insights.svg';
import InventoryIcon from '../../../assets/inventory.svg';

import { NavLink } from "react-router-dom";

function BottomTab (){
    useEffect(()=>{},[])
    return (
        <div className="bottom-tab">
            <div className="tabs">
                <NavLink to="home" className="tab">
                    <div className="tab-icon"><img src={HomeIcon} alt="home icon" /></div>
                    <p className="tab-name">Home</p>
                </NavLink>
                <NavLink to="orders" className="tab">
                    <div className="tab-icon"><img src={OrdersIcon} alt="orders icon" /></div>
                    <p className="tab-name">Orders</p>
                </NavLink>
                <NavLink to="insights" className="tab">
                    <div className="tab-icon"><img src={InsightsIcon} alt="insights icon" /></div>
                    <p className="tab-name">Insights</p>
                </NavLink>
                <NavLink to={'menu'} className="tab">
                    <div className="tab-icon"><img src={MenuIcon} alt="menu icon" /></div>
                    <p className="tab-name">Menu</p>
                </NavLink>
                <NavLink to={'inventory'} className="tab">
                    <div className="tab-icon"><img src={InventoryIcon} alt="menu icon" /></div>
                    <p className="tab-name">Inventory</p>
                </NavLink>
                {/* <NavLink to={'profile'} className="tab">
                    <div className="tab-icon"><img src={ProfileIcon} alt="profile icon" /></div>
                    <p className="tab-name">Profile</p>
                </NavLink> */}
            </div>
        </div>
    )
}

export default BottomTab;