import './home.scss';
import Logo from '../../assets/logo.png';
import foodLogo from '../../assets/food-logo.png';
import Footer from '../footer/footer';
import { useNavigate } from 'react-router';


function Landingpage(){

    const navigate = useNavigate()
    
    return (
        <>    
        <div className="Home">
            <div className="la-costa-fixed-watermark"></div>
            <div className="business-card">
            <img src={Logo} alt="business logo" />
            <div className="business-button" onClick={e=>navigate('/business-info')}>Visit business site</div>
            </div>
            <div className="food-card">
                <img src={foodLogo} alt="food logo" />
                <div className="food-button" onClick={e=>navigate('/order')}>Visit ordering site</div>
            </div>
        </div>
        <Footer></Footer>
        </>
    )
    
}

export default Landingpage;