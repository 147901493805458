import './business-site.scss';
import BannerImage from '../../assets/logo.png';
import LocationIcon from '../../assets/location_home.png';
import OrderIcon from '../../assets/order.png';
import PayIcon from '../../assets/pay.png';
import MealsIcon from '../../assets/meals.png';
import Footer from '../footer/footer';
import Feature1Image from '../../assets/Feature 1.png';
import Feature2Image from '../../assets/Feature 2.png';
import DownArrow from '../../assets/down-arrow.svg';
import { useEffect, useState } from 'react';
import { storage } from '../../firebase/firebase';
import NewRestaurantService from '../../firebase/new-restaurant'

function BusinessSite(){
    
    const [state,setState] = useState({
        name:'',
        restaurant_name:'',
        phone:'',
        files:'',
        urls:[],
        submit:false
    })
    const storageRef = storage.ref();

    const onChangeField = (e)=>{
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    }

     const addNewUser = ()=>{
        if(state.name&&state.phone&&state.restaurant_name&&state.urls.length){
            let data = {
                name:state.name,
                phone:state.phone,
                restaurant_name:state.restaurant_name,
                urls:state.urls
            };
            NewRestaurantService.create(data)
            setState({
                name:'',
                restaurant_name:'',
                phone:'',
                files:'',
                urls:[],
                submit:false
            })
            var x = document.getElementById("snackbar")
            x.className = "show";
            setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
        } else {
            alert('All fields are required!!!')
        }
    }

    const handleFileChange = (e) => {
        setState({
            ...state,
            files:e.target.files});
       
                handleUpload(e.target.files)
           
    };


    const handleUpload = (files) => {
        // Iterate through each selected file
        setState({...state,submit:true})
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          const uploadTask = storageRef.child(file.name).put(file);
    
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              console.log(progress,'progress');
            },
            (error) => {
              console.error(error);
            },
            () => {
              // Upload completed successfully
              var urls = state.urls
              uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                urls.push(url)
              });
              setState({...state,urls:urls,submit:false})


              console.log(0,'progress');
              console.log('File uploaded successfully');
            }
          );
        }
      };

    return (
        <> 
        <div className="business">

           <div className="business-banner">
               <div className="business-banner-image">
                   <img src={BannerImage} alt="banner image" />
               </div>
               <div className="business-banner-content">
                   <h1>Are you starving?</h1>
                   <p>Within a few clicks, find meals that are accessible at your table</p>
               </div>
           </div>

           <div id="snackbar">Our team will soon contact you...</div>


           <div className="business-work">
               <h3>How does it work</h3>
               <div className="business-card business-location">
                   <img src={LocationIcon} alt="location icon" />
                   <h4>Select location</h4>
                   <p>Choose the location where you like to have your meal</p>
               </div>
               <div className="business-card business-order">
                   <img src={OrderIcon} alt="order icon" />
                   <h4>Choose order</h4>
                   <p>Check over hundreds of menus to pick your favorite food</p>
               </div>
               <div className="business-card business-pay">
                   <img src={PayIcon} alt="pay icon" />
                   <h4>Pay advanced</h4>
                   <p>It's quick, safe, and simple. Select several methods of payment</p>
               </div>
               <div className="business-card business-meals">
                   <img src={MealsIcon} alt="meals icon" />
                   <h4>Enjoy meals</h4>
                   <p>Food is made and delivered directly at your table.</p>
               </div>


           </div>

           <div className="business-feature-1">
               <img src={Feature1Image} alt="" />
               <div className="content">
                   <h4>Create Your Own Scan App</h4>
                   <p>Convert all your guests in loyal customers with your own white-labelled Scan app.
                      Increase repeat visits of your guests and motivate them to spend more.</p>
                    <button>Explore</button>
               </div>
           </div>

           <div className="business-feature-2">
               <img src={Feature2Image} alt="" />
               <div className="content">
                   <h4>Create Your Own Takeaway App</h4>
                   <p>Convert all your guests in loyal customers with your own white-labelled Takeaway app.
                      Increase repeat visits of your guests and motivate them to spend more.​</p>
                    <button>Explore</button>
               </div>
           </div>

           <div className="business-form">
               <div className="form-container">
                   <h3>Get early access</h3>
                   <p>Start re-marketing your restaurant like a pro.</p>
                   <p>Try now for free !!!</p>

                   <div className="form-fields">
                       <input value={state.name}  type="text" onChange={e=>onChangeField(e)} name="name" placeholder="Your name"/>
                       <input value={state.restaurant_name}  type="text" onChange={e=>onChangeField(e)} name="restaurant_name" placeholder="Your restuarant name"/>
                       <input value={state.phone}  type="number" onChange={e=>onChangeField(e)} name="phone" placeholder="Phone"/>
                       <input type="file" multiple onChange={e=>handleFileChange(e)} />
                   </div>
                   <button disabled={state.submit} onClick={e=>addNewUser(e)}>Submit</button>
               </div>
           </div>

           <div className="business-faq">
               <h3>Frequently asked questions</h3>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>What is LaCarte engage and how does it work?</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">

                   <p>La Carte Engage is a re-marketing app to reach, engage and grow your business 
                           with incremental visits from your customers. You can sign-up, add a customer 
                           database choose prescribed campaigns and start distribution via SMS, email & Whatsapp. 
                           It’s the simple and fastest way to take your restaurant marketing functions to next level.</p>
                     

                   </div>
               </div>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>What are the main benefits of using LaCarte Engage ?</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">
                   <p>Restaurant Re-marketing on autopilot <br />
                       Drive incremental visits <br />
                       Brand Re-call for customers <br />
                       Build guest data & segmentation to sell more
                       </p>
                   </div>
               </div>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>How can I sign up?</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">
                       <p>Fill up the demo request form. Our team will get in touch with you within 24Hrs for signup and assist further.</p>
                   </div>
               </div>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>How does your pricing work?</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">
                       <p>We offer commission based plans, the commission can vary between 1-8%.  you will be only charged when the sale is through our application.</p>
                   </div>
               </div>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>Does LaCarte provide guests' insights ?</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">
                       <p>Yes, with LaCarte you’ll receive all of the customer data to drive your business and marketing strategy.</p>
                   </div>
               </div>
               <div className="accordian">
                   <div className="accordian-tab">
                       <p>Documents required to sign up to LaCarte</p>
                       <img src={DownArrow} alt="down arrow" />
                   </div>
                   <div className="accordian-content">
                       <p>Complete details of the restaurant/Cafe, GST Certificate, FSSAI license.</p>
                   </div>
               </div>
           </div>

        </div>
        <Footer></Footer>
        </>
    )
}

export default BusinessSite;