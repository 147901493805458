import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const LineChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && data) {
      const chart = chartRef.current.chart;

      const xValues = data.map(item => item.x);
      const yValues = data.map(item => item.y);

      const options = {
        chart: {
          type: 'line'
        },
        title: {
          text: 'Sales'
        },
        xAxis: {
          categories: xValues,
          title: {
            text: 'Months'
          }
        },
        yAxis: {
          title: {
            text: 'Amount'
          }
        },
        series: [{
          name: 'Data',
          data: yValues
        }]
      };

      chart.update(options);
    }
  }, [data]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: 'line'
        },
        title: {
          text: 'Sales'
        },
        series: [{
          name: 'Data',
          data: []
        }]
      }}
      ref={chartRef}
    />
  );
};

export default LineChart;
