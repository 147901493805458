import React, { Component } from 'react'
import QrReader from 'modern-react-qr-reader'
import './scanner.scss';
import { useNavigate } from 'react-router';

const Scanner = ()=> {
    
  const navigate = useNavigate();

  const handleScan = data => {
    if (data) {
        if(data.includes('thelacarte.com/')){
           let route= data.split('thelacarte.com')[1]
           navigate(route)
        } else {
           alert('Please use la costa qr code only')
        }
    }
  }
  
  const handleError = err => {
    console.error(err)
  }
  
    return (
      <div className="scanner">
        <div className="la-costa-fixed-watermark"></div>
        <QrReader
          delay={300}
          facingMode={"environment"}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </div>
    )
  
}

export default Scanner