import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import './table-orders.scss'
import RestaurantDataService from '../../../firebase/restaurant';
import waiterIcon from '../../../assets/waiter.svg'

function TableOrders(){
    const [orders,setOrders] = useState({});
    const [waiter,setWaiter] = useState();
    const params = useParams();

    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();

    useEffect(()=>{
        console.log(searchParams.get('table'),params)
        RestaurantDataService.getTableOrder(params).on('value',dataChange)
        window.$('#root .bottom-tab').hide()
        let key ={ id:params.id,table:params.tableId}

        RestaurantDataService.getTableWaiterStatus(key).on('value',function(item){
            setWaiter(item.val())
         })
        return ()=>{
            RestaurantDataService.getTableWaiterStatus(key).off('value',function(item){
                setWaiter(item.val())
             })
            window.$('#root .bottom-tab').show()
            RestaurantDataService.getTableOrder(params).off('value',dataChange)
        }
    },[])

    const dataChange = (item)=>{
        console.log('items',item.val())
        setOrders(item.val())
    }

    const acceptOrder = (value,item)=>{
        value['key']=item
        value['status']='accepted'
        setOrders({
            ...orders,
            [item]:value    
        })
        console.log(value,item)
        let keys = {
            id:params.id,
            tableId:params.tableId,
            orderId:item
        }
        RestaurantDataService.updateTableOrder(keys,value)
    }

    const declineOrder = (value,item)=>{
        value['key']=item
        value['status']='declined'
        setOrders({
            ...orders,
            [item]:value    
        })
        console.log(value,item)
        let keys = {
            id:params.id,
            tableId:params.tableId,
            orderId:item
        }
        RestaurantDataService.updateTableOrder(keys,value)
    }

    const deliveredOrder = (value,item)=>{
        value['key']=item
        value['status']='delivered'
        console.log(value,item)
        setOrders({
            ...orders,
            [item]:value    
        })
        let keys = {
            id:params.id,
            tableId:params.tableId,
            orderId:item
        }
        RestaurantDataService.updateTableOrder(keys,value)
    }

    const clearTable = () => {
        if(orders&&Object?.keys(orders)?.length){
            let keys = {
                id:params.id,
                tableId:params.tableId
            }
            orders['fromTable']=params.tableId
            orders['tableClear']=Date.now()
            console.log(orders)
            RestaurantDataService.addOrdersToRestaurant(keys,orders)
            RestaurantDataService.clearTableOrder(keys)
            navigate('/admin/restaurant/'+params.id+'/home')
        } else {
            navigate('/admin/restaurant/'+params.id+'/home')
        }
    }

    const updateWaiter=(value)=>{
        let key ={ id:params.id,table:params.tableId}
        RestaurantDataService.updateTableWaiterStatus(key,value)
    }

   return (
       <div className="table-orders">
           <div className="la-costa-fixed-watermark"></div>
           <p className="heading">Order for {searchParams.get('table')==='Take-away'?'Take-away':'table '+searchParams.get('table')}</p>

           <div className="table-orderslist">
           {

                orders&&Object.keys(orders).length?
                Object.keys(orders).map((item,index)=>{
                    if(orders[item]&&orders[item]?.products)
                    return (
                        <div className="table-order" key={'order-'+item}>
               <div className="table-order-content">
                   {

                       Object.keys(orders[item]?.products)?.map((p)=>{
                           return (
                               <p>{orders[item]?.products[p]?.name} x {orders[item]?.products[p]?.quantity} <span>₹ {orders[item]?.products[p]?.price}</span></p>
                           )
                       })
                   }

                 
                   <p className="total">Total <span>₹ {orders[item].totalPrice}</span></p>
               </div>
               {
                   !orders[item].status?
                   <>    
               <div className="table-order-button" onClick={e=>{
                   acceptOrder(orders[item],item)
               }}>
                   <p>
                   {'Accept'}
                   </p>
                </div>
                <div className="table-order-button" onClick={e=>{
                    declineOrder(orders[item],item)
                }}>
                   <p>
                   Decline
                   </p>
                </div>
                   </> :
                <div className="table-order-button" onClick={e=>{
                    if(orders[item].status==='accepted')
                    deliveredOrder(orders[item],item)
                }}>
                   <p>
                   {orders[item].status==='declined'?'Declined':orders[item].status!=='delivered'?'Mark as delivered':'Completed'}
                   </p>
                </div>
               }

                        </div>
                    )
               }) :
               <p>There is no order placed from this table</p>
           }


          
           </div>

           {
            waiter?
          <div className="waiter-button" onClick={e=>{updateWaiter(false)}}>
              <img src={waiterIcon} alt="waiterIcon" />
          </div>:''
        }

           <div className="clear-table" onClick={e=>{clearTable()}}>
               {
                orders&&Object.keys(orders).length?
                'Clear table':'Back to home'
               }
           </div>

       </div>
   )
}

export default TableOrders;