import { useEffect, useState } from 'react';
import './home.scss';
import tableIcon from '../../../assets/table.svg';
import BottomTab from '../bottom-tab/bottom-tab';
import { useNavigate, useParams } from 'react-router';
import RestaurantDataService from '../../../firebase/restaurant'

function Home(){
    const [restaurantTables,setRestaurantTables] = useState();

    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(()=>{
        RestaurantDataService.get(id).on('value',dataChange)
        return ()=>{
        RestaurantDataService.get(id).off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
        console.log(data,'data')
        setRestaurantTables({...data.val(),key:data.key})
    }

    const alertNotification = (table)=>{
        if ('Notification' in window) {
            Notification.requestPermission().then(function (permission) {
              if (permission === 'granted') {
                new Notification('Inventory Alert', {
                  body: 'I need assistance at table '+table+'.',
                });
              }
            });
        }
    }

  return (
      <div className="home">
          <div className="la-costa-fixed-watermark"></div>
          <p className="restaurant-name">
              Hello, <br />
              {restaurantTables?.name}
          </p>
          <div className="tables">
              <p className="heading">Your orders</p>
              <div className="table-items">
                  {
                      restaurantTables?.tables?
                      Object.keys(restaurantTables?.tables)?.map((item,index)=>{
                          return (
                  <div className="table-item" onClick={e=>{navigate('table-orders/'+item+'?table='+(index!==(Object.keys(restaurantTables?.tables).length-1)?(index+1):'Take-away'))}}>
                      <img src={tableIcon} alt="table icon" />
                      {
                          restaurantTables?.tables[item]?.waiter?
                          <span className="waiter">{alertNotification((Object.keys(restaurantTables?.tables).length-1)?(index+1):'Take-away')}</span>:''
                      }
                      <p>{index!==(Object.keys(restaurantTables?.tables).length-1)?'Table no. '+(index+1):'Take-away'}</p>
                      <p className="orders">{restaurantTables?.tables[item]?.orders?Object.keys(restaurantTables?.tables[item]?.orders)?.length:'0'}</p>
                  </div>
                          )
                      }) :''
                  }
              </div>
          </div>
      </div>
  )
}

export default Home;