class CartDataService {

  getCart() {
    return JSON.parse(localStorage.getItem('cart'));
  }

  getCartItemQuantity(id) {
    let cart  =  JSON.parse(localStorage.getItem('cart'));
    return cart?.[id]?.quantity
  }

  isCartEmpty(){
      if(Object.keys(this.getCart()).length){
          return false
      } else {
          return true;
      }
  }

  addToCart(item) {
      let cart  =  JSON.parse(localStorage.getItem('cart'));
      let quantity = 0
      if(cart?.[item?.key]?.quantity){
         quantity = cart?.[item?.key]?.quantity+1
      } else {
          quantity = 1;
      }
      let cartItem ={
          ...item,
          quantity:quantity,
      }
      let newCart = {
          ...cart,
          [item.key]:cartItem
        }
      localStorage.setItem('cart',JSON.stringify(newCart))
  }

  removeToCart(item) {
    var cart  =  JSON.parse(localStorage.getItem('cart'));
    var quantity = cart?.[item?.key]?.quantity
    if(quantity>1){
        console.log(cart,item.key)
        cart[item.key].quantity = quantity-1;
    } else {
       delete cart[item.key]
    }
    localStorage.setItem('cart',JSON.stringify(cart))
  }

  getTotalItems(){
    let cart  =  JSON.parse(localStorage.getItem('cart'));
    let ans = 0;
    if(cart&&Object.keys(cart).length)
    Object.keys(cart).forEach(item=>{
        ans+=cart[item].quantity
    })
    return ans;
  }

  deleteCartItem(id){
    let cart  =  JSON.parse(localStorage.getItem('cart'));
    delete cart[id]
    localStorage.setItem('cart',JSON.stringify(cart))
  }

  deleteCartItems(){
    localStorage.setItem('cart',JSON.stringify({}))
  }




  
}

export default new CartDataService();