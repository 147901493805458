import { useEffect, useState } from 'react';
import Logo from '../../assets/logo.png';
import Scan from '../../assets/scan.png'
import './homepage.scss'
import Restaurant from '../../assets/Restaurant Image.png'
import { useNavigate } from 'react-router';
import RestaurantDataService from '../../firebase/restaurant';
import Footer from '../footer/footer'

function Homepage(){
    const [scan,setScan] = useState(true);
    const [restaurants,setRestaurants] = useState([])
    const [restaurantsCopy,setRestaurantsCopy] = useState([])


    useEffect(()=>{
        RestaurantDataService.getAll().on('value',dataChange)
        return ()=>{
        RestaurantDataService.getAll().off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
        let restaurant = []
        data.forEach((item)=>{
            let data = {
                ...item.val(),
                key:item.key
            }
            restaurant.push(data)
        })
        console.log(restaurant)
        setRestaurants(restaurant)
        setRestaurantsCopy(restaurant)

    }

    const navigate = useNavigate();

    const tabSwitch = (value) =>{
        if(value==='scan'){
            setScan(true)
        } else {
            setScan(false)
        }
    }

    const goToRestaurant = (id) =>{
        navigate('/restaurant/'+id)
    }

    const filterRestaurant = (e)=>{
        console.log(e.target.value)
        let newRes = restaurantsCopy.filter((item)=>item.name.includes(e.target.value))
        setRestaurants(newRes)
    }

    const filterLocation = (e) =>{
        let newRes = restaurantsCopy.filter((item)=>{
            console.log(e.target.value.toLowerCase(),item.location.toLowerCase())
         return item.location.toLowerCase().includes(e.target.value.toLowerCase())})
        console.log(e.target.value,restaurantsCopy,newRes)
        setRestaurants(newRes)
    }

    return (
        <>
        <div className="homepage">
            <div className="logo">
                <img src={Logo} alt="" />
            </div>
            <div className="tabs">
                <div className={`tab ${scan?'active':''}`} onClick={e=>{tabSwitch('scan')}}>
                    <p>
                    SCAN CODE
                    </p>
                    </div>
                <div className={`tab ${!scan?'active':''}`} onClick={e=>{tabSwitch('take-away')}}>
                    <p>TAKE-AWAY</p>
                </div>
            </div>
            {
                scan?
            <div className="scan">
                <div className="scan-image">
                 <img src={Scan} alt="" />
                </div>
                <div className="scan-button" onClick={e=>{navigate('/scanner')}}>
                    SCAN NOW 
                </div>
            </div>:
            <div className="takeaway">
                <div className="filter-takeaway">
                    <div className="location">
                        <select name="location" id="location" defaultValue="" onChange={e=>{filterLocation(e)}}>
                            <option disabled value="">Select location</option>
                            <option value="Pushkar">Pushkar</option>
                        </select>
                    </div>
                    <div className="search">
                        <input type="text" placeholder="Search restaurant" onChange={e=>{filterRestaurant(e)}}/>
                    </div>
                </div>
                <div className="restaurants">
                    {
                        restaurants.map((item,value)=>{
                          return (
                    <div className="restaurant" onClick={e=>{goToRestaurant(item.key)}}>
                        <div className="restaurant-image">
                            <img src={item.icon} alt="" />
                        </div>
                        <p>{item.name}</p>
                    </div>
                          )  
                        })
                    }
                </div>

            </div>
            }
        </div>
        <Footer></Footer>
        </>
    )
}

export default Homepage;