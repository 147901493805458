import { useEffect, useState } from "react";
import './edit-category.scss';
import deleteIcon from '../../../assets/delete.svg';
import { useNavigate, useParams } from "react-router";
import RestaurantDataService from '../../../firebase/restaurant'
import uuid from 'react-uuid';

function EditCategory(){
    const [category,setCategory] = useState({
        name:'',
        subCategory:[{
            name:'',
            products:{},
            key:uuid()
        }]
    })

    const params = useParams();

    const navigate = useNavigate()

    const addSubCategory = ()=>{
        let newSubCategory = [...category.subCategory]
        newSubCategory.push({
            name:'',
            products:{},
            key:uuid()
        })

        console.log(newSubCategory)

        setCategory({
            name:category.name,
            subCategory:newSubCategory
        })
    }

    const deleteSubCategory = (index)=>{
        let newSubCategory = [...category.subCategory]
        newSubCategory = newSubCategory.filter((item,i)=>i!==index)

        setCategory({
            name:category.name,
            subCategory:newSubCategory
        })
    }

    useEffect(()=>{
        if(params.subId)
        RestaurantDataService.getRestaurantMenuCategory(params).on('value',dataChange)
        window.$('#root .bottom-tab').hide()
        return ()=>{
            window.$('#root .bottom-tab').show()
            if(params.subId)
            RestaurantDataService.getRestaurantMenuCategory(params).off('value',dataChange)
        }
    },[])

    const dataChange = (data)=>{
        console.log(data.val())
        let newData = data.val();
        let dataSet = {
            name:newData.name,
            subCategory:Object.keys(newData.subCategory).map((item)=>{
                return {
                    key:item,
                    ...newData.subCategory[item]
                }
            })
        }
        setCategory(dataSet)
    }

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    const saveCategory = () => {
        let data = {
            name:category.name,
            subCategory:convertArrayToObject(category.subCategory,'key')
        }
        console.log(data)
        if(params.subId){
            RestaurantDataService.editRestaurantMenuCategory(params,data)
        } else {
            RestaurantDataService.addRestaurantMenuCategory(params,data)
        }
        navigate('/admin/restaurant/'+params.id+'/menu')
    }

    const deleteCategory = () =>{
        RestaurantDataService.deleteRestaurantMenuCategory(params)
        navigate('/admin/restaurant/'+params.id+'/menu')
    }

    const updateSubCategory = (e,index)=>{
        let newSubCategory = category.subCategory.map((item,i)=>{
            if(i==index)
            return {
                ...item,
                name:e.target.value,
            }
            else 
            return item
        })
        setCategory({
            name:category.name,
            subCategory:newSubCategory
        })
    }

    return (
        <div className="edit-category">
           <div className="la-costa-fixed-watermark"></div>

            <p className="heading">{params.subId?'Update category':'Add category'}</p>

            <div className="category-name">
                <label htmlFor="Category">Category</label>
                <input type="text" placeholder="Category name..." name="name" value={category.name} onChange={e=>{setCategory({
                    ...category,
                    name:e.target.value
                })}}/>
            </div>

            <div className="sub-category-name">

                <p>Sub categories</p>
                {
                    category.subCategory.map((item,index)=>{
                        return (
                <div className="sub-category-field">
                   <input type="text" placeholder="Sub category name..." value={item.name} onChange={e=>{
                       updateSubCategory(e,index)
                   }}/>
                    {
                       category.subCategory.length>1?
                       <div className="delete" onClick={e=>{deleteSubCategory(index)}}><img src={deleteIcon} alt="" /></div>:''
                    }
                </div>
                        )
                    })
                }


                <div className="add-subCategory" onClick={e=>{
                    addSubCategory()
                }}>Add new sub category</div>
            </div>

            <div className="save-button" onClick={e=>{saveCategory()}}>Save</div>
            {
                params.subId?
                    <div className="delete-button" onClick={e=>{deleteCategory()}}>Delete</div>:''
            }

        </div>
    )
}

export default EditCategory;